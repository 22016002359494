import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import StyledHero from "../components/styledHero"
import SEO from "../components/seo"
import Title from "../components/title"
import styles from "../css/text.module.css"

const Privacy = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Datenschutzerklärung" />
      <StyledHero img={data.privacyBcg.childImageSharp.fluid} />
      <section className={styles.text}>
        <Title title="Datenschutzerklärung" />
        <div className={styles.center}>
          <article>
            <h4>Allgemeiner Hinweis und Pflichtinformationen</h4>
            <p>
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
            </p>
            <p>
              Beceptum International GmbH
              <br />
              Olaf Saß
              <br />
              Trautenwolfstr. 5<br />
              80802 Munich, Germany
            </p>
            <p>
              Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
              anderen über die Zwecke und Mittel der Verarbeitung von
              personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
            </p>
            <h4>
              Erheben und Verwenden nicht personenbezogener Daten bei Nutzung
              der Website
            </h4>
            <p>
              Wenn Sie diese Website nutzen, ohne anderweitig (z. B. durch
              Registrierung) Daten an uns zu übermitteln, erheben wir nur
              technisch notwendige Daten, die automatisch durch Ihren Browser an
              unseren Server übermittelt werden (z. B.
              Browsertyp/Browserversion, verwendetes Betriebssystem, Referrer
              URL, aufgerufene Seiten, Verweildauer, IP-Adresse, Datum und
              Uhrzeit der Anfrage) oder die durch technisch notwendige oder
              funktionelle Cookies erforderlich sind. Soweit davon
              personenbezogene Daten betroffen sind, verarbeiten wir diese auf
              Grundlage unseres berechtigten Interesses, Ihnen eine
              funktionierende, sichere Website zur Verfügung zu stellen (Art. 6
              Abs. 1 lit. f DSGVO) Eine Zusammenführung dieser Daten mit anderen
              Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese
              Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für
              eine rechtswidrige Nutzung bekannt werden.
            </p>
            <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
            <p>
              Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
              Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
              Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
              formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </p>
            <h4>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h4>
            <p>
              Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
              Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
              zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher
              Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in
              dem sich der Sitz unseres Unternehmens befindet. Der folgende Link
              stellt eine Liste der Datenschutzbeauftragten sowie deren
              Kontaktdaten bereit:
              <a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html{" "}
              </a>
              .
            </p>
            <h4>Recht auf Datenübertragbarkeit</h4>
            <p>
              Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
              Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern
              Sie die direkte Übertragung der Daten an einen anderen
              Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
              machbar ist.
            </p>
            <h4>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h4>
            <p>
              Sie haben jederzeit im Rahmen der geltenden gesetzlichen
              Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, Herkunft der Daten, deren
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung, Sperrung oder Löschung dieser Daten.
              Diesbezüglich und auch zu weiteren Fragen zum Thema
              personenbezogene Daten können Sie sich jederzeit über die im
              Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
            </p>
            <h4>SSL- bzw. TLS-Verschlüsselung</h4>
            <p>
              Aus Sicherheitsgründen und zum Schutz der Übertragung
              vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden,
              nutzt unsere Website eine SSL-Verschlüsselung. Damit sind Daten,
              die Sie über diese Website übermitteln, für Dritte nicht
              mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der
              „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der
              Browserzeile.
            </p>
            <h4>Kontaktformular</h4>
            <p>
              Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
              Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können
              oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser
              Daten findet ohne Ihre Einwilligung nicht statt.
            </p>
            <p>
              Die Verarbeitung der in das Kontaktformular eingegebenen Daten
              erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6
              Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten
              Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
              formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
              unberührt.
            </p>
            <p>
              Über das Kontaktformular übermittelte Daten verbleiben bei uns,
              bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
              Speicherung widerrufen oder keine Notwendigkeit der
              Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen
              - insbesondere Aufbewahrungsfristen - bleiben unberührt.
            </p>

            <h4>Social Plugins</h4>
            <p>
              Wir stellen Ihnen auf unserer Website Social Plugins der Sozialen
              Netzwerke Facebook, LinkedIn und Twitter zur Verfügung. Es handelt
              sich dabei um Angebote der US-amerikanischen Unternehmen Facebook
              Inc. (1601 S. California Ave, Palo Alto, CA 94304, USA), LinkedIn
              Ireland Unlimited Company( Wilton Place, Dublin 2, Irland) und
              Twitter Inc. (1355 Market Street, Suite 900, San Francisco CA
              94103). Die Plugins erkennen Sie an den jeweiligen Logos der
              Unternehmen. Diese Plugins können Sie dazu nutzen, um direkt auf
              dem jeweiligen Netzwerk zu liken, zu sharen oder anderweitig aktiv
              zu sein.
            </p>
            <p>
              Durch die Betätigung des Social Plugins erklären Sie sich mit der
              Erhebung Ihrer personenbezogenen Daten durch das jeweilige
              Netzwerk einverstanden. Für die dann stattfindende Datenerhebung
              und -verarbeitung durch das Soziale Netzwerk, einschließlich des
              Einsatzes von Cookies und ähnlichen Technologien durch das Soziale
              Netzwerk, ist das jeweilige Soziale Netzwerk verantwortlich.
              Konkret werden dann Informationen dazu, welche unserer
              Internetseiten Sie besucht haben, und Ihre IP-Adresse an das
              Soziale Netzwerk übermittelt, auch wenn Sie kein Profil bei dem
              jeweiligen Netzwerk besitzen oder dort gerade nicht eingeloggt
              sind. Sind Sie als Mitglied bei den Sozialen Netzwerken
              eingeloggt, ordnet das Netzwerk die Informationen Ihrem
              persönlichen Benutzerkonto zu.
            </p>
            <p>
              Wir empfehlen Ihnen daher, vor Betätigung des Social Plugins, die
              Datenschutzerklärung des jeweiligen Netzwerkes zu lesen:
            </p>
            <ul>
              <li>
                Weitere Informationen zur Verarbeitung Ihrer personenbezogenen
                Daten durch Facebook erhalten Sie in der Facebook
                Datenschutzerklärung unter:
                <a
                  href="https://de-de.facebook.com/policy.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  https://de-de.facebook.com/policy.php
                </a>
              </li>
              <li>
                Weitere Informationen zur Verarbeitung Ihrer personenbezogenen
                Daten durch LinkedIn erhalten Sie in der LinkedIn
                Datenschutzerklärung unter:
                <a
                  href="https://www.linkedin.com/legal/privacy-policy?_l=de_DE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  https://www.linkedin.com/legal/privacy-policy?_l=de_DE
                </a>
              </li>
              <li>
                Weitere Informationen zur Verarbeitung Ihrer personenbezogenen
                Daten durch Twitter erhalten Sie in der Twitter
                Datenschutzerklärung unter:
                <a
                  href="https://twitter.com/de/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  https://twitter.com/de/privacy
                </a>
              </li>
            </ul>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    privacyBcg: file(relativePath: { eq: "privacyBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Privacy
